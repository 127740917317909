@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .active-nav-link{
        @apply pointer-events-auto text-left w-full rounded p-1 text-grey-text-main bg-secondary-light flex justify-between;
    }
    .nav-link{
        @apply pointer-events-auto text-left w-full rounded p-1 hover:bg-secondary-main hover:text-white text-grey-text-main flex justify-between;
    }
}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    html {
        @apply h-full tracking-wider bg-gray-300;
        font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", メイリオ, Meiryo, sans-serif;
    }
    button:disabled {
        @apply opacity-30;
    }
    h1 {
        @apply text-4xl font-extrabold
    }
    h2 {
        @apply text-2xl font-semibold
    }
    h3,
    h4,
    h5,
    h6 {
        @apply text-xl font-semibold
    }

    html:has(.anchor-scroll) {
        @apply scroll-smooth;
    }

     input::placeholder {
        @apply text-gray-300;
    }

    input:-ms-input-placeholder {
        @apply text-gray-300;
    }

    input::-ms-input-placeholder {
        @apply text-gray-300;
    }
}


.ce-block__content, .ce-toolbar__content {
    max-width:calc(100% - 80px);
}
.cdx-block {
    max-width: 100%;
}
.multiCheckbox + p {
    display: none;
}
.multiCheckbox:checked + p {
    display: block;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.nda-documents {
    background-color: var(--w-e-textarea-bg-color);
    color: var(--w-e-textarea-color);
    height: 100%;
    position: relative;
    width: 100%;
}
.nda-documents ol, .nda-documents ul {
    margin-left: 20px;
}

.nda-documents ol {
    list-style: decimal;
}

.nda-documents ul {
   list-style: disc;
}

.nda-documents .paragraph {
    margin-top: 4px;
    margin-bottom: 4px;
}

.nda-documents input, .nda-documents textarea {
    color: #F2921C;
}

.nda-documents table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    margin-bottom: 10px;
}

.nda-documents table th {
    background-color: var(--w-e-textarea-slight-bg-color);
    font-weight: 700;
    text-align: center;
}

.nda-documents table td, .nda-documents table th {
    border: 1px solid var(--w-e-textarea-border-color);
    line-height: 1.5;
    min-width: 30px;
    padding: 3px 5px;
    text-align: left;
}

@media only screen and (max-width: 650px) {
    .ce-block__content, .ce-toolbar__content {
        max-width: 100%;
    }
}
